
































import {Vue, Component} from "vue-property-decorator";

@Component({
  name: "NewBoardResponsive",
})

export default class NewBoardResponsive extends Vue {

}
